import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: (function () {
      // if(localStorage) {
      //   if (localStorage.getItem("lang")) {
      //     return localStorage.getItem("lang");
      //   }
      // }
      
      return "en";
    })(),
    messages: {
      en: require("./locales/en"), //英文语言包
      zh: require("./locales/zh"), //中文简体包
      ar: require("./locales/ar"), //阿拉伯
      de: require("./locales/de"), //德语
      ja: require("./locales/ja"), //日文
      es: require("./locales/es"), //西班牙文
      fr: require("./locales/fr"), //法语
      pt: require("./locales/pt"), //葡萄牙语
      hi: require("./locales/hi"), //印地语
      tr: require("./locales/tr"), //土耳其语
      id: require("./locales/id"), //印尼语
      ko: require("./locales/ko"), //韩语
      it: require("./locales/it"), //意大利语
      th: require("./locales/th"), //泰语
      ru: require("./locales/ru"), //俄语
      zh_TW: require("./locales/zh_TW"), //繁体中文（台）
      zh_HK: require("./locales/zh_HK"), //繁体中文（香港）
    },
  });
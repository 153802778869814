import Vue from "vue";
import 'animate.css';
import App from "./App.vue";
import {Drawer,Loading,Message,Collapse,CollapseItem} from "element-ui";
// import VueI18n from "vue-i18n";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/font/index.css"
import 'es6-promise/auto'
import router from "./router";
import VueClipBoard from "vue-clipboard2";
// import VueGtag from "vue-gtag";
import {i18n} from '@/i18n'
import store from './store'
import 'amfe-flexible'
// Vue.prototype.$i18n = i18n
Vue.config.productionTip = false;
// Vue.use(Element, {
//   size: "small", // set element-ui default size
// });
Vue.use(Drawer)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Loading.directive)
Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.use(VueClipBoard);
// Vue.use(VueGtag, {
//   config: { id: "G-CNGN41K7R9" }
// });
// 在每次路由进入之前调用
router.beforeEach((to, from, next) => {
  // 调用友盟pv埋点
  sendPvPoint({to:to.fullPath,from: from.fullPath});
  next(); // 必须调用next()来resolve这个钩子
});

// pv埋点
const sendPvPoint = async (eventParams) => {
  console.log('pv埋点:',eventParams)
  try {
      const { aplus_queue } = window
      if (!aplus_queue) {
          // console.log('----埋点未初始化-pv--')
          return
      }
      // console.log('----埋点-pv-send-')

      aplus_queue.push({
          action: 'aplus.sendPV',
          arguments: [
              {
                  is_auto: false,
              },
              {
                  current_path: window.location.href, // 页面路径
                  event_name_cn: '浏览',
                  event_name_en: 'pv',
                  event_type: 'browse',
                  ...(eventParams || {}),
              },
          ],
      })
  } catch (error) {
      console.error(error)
  }
}
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

const state = {
  token: "",
  lang: "en",
  model: "",
  platform: 1,
  version: "1.0.0",
};
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_LANG: (state, lang) => {
    state.lang = lang;
  },
  SET_MODEL: (state, model) => {
    state.model = model;
  },
  SET_PLATFORM: (state, platform) => {
    state.platform = platform;
  },
  SET_VER: (state, version) => {
    state.version = version;
  },
};
const actions = {
  setToken({ commit }, token) {
    commit("SET_TOKEN", token);
  },
  setLang({ commit }, lang) {
    commit("SET_LANG", lang);
  },
  setModel({ commit }, model) {
    commit("SET_MODEL", model);
  },
  setPlatform({ commit }, platform) {
    commit("SET_PLATFORM", platform);
  },
  setVer({ commit }, version) {
    commit("SET_VER", version);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

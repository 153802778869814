import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: "/",
    name: 'home',
    component: ()=> import(/* webpackChunkName: "about" */ "../views/home/index.vue"),
  },
  {
    path: "/invite",
    name: 'invite',
    component: ()=> import(/* webpackChunkName: "about" */ "../views/invite/index.vue"),
  },
  {
    path: "/faq",
    name: 'faq',
    component: ()=> import(/* webpackChunkName: "about" */ "../views/faq/index.vue"),
  },
  {
    path: "/dec_bugevent_1215",
    name: 'dec_bugevent_1215',
    component: ()=> import(/* webpackChunkName: "about" */ "../views/dec_bugevent_1215/index.vue"),
  },
  {
    path: "/model",
    name: 'model',
    component: ()=> import(/* webpackChunkName: "about" */ "../views/model/index.vue"),
  },
  // {
  //   path: "/download",
  //   name: 'download',
  //   component: ()=> import(/* webpackChunkName: "about" */ "../views/download/index.vue"),

  // },
  {
    path: "/404",
    component: ()=> import(/* webpackChunkName: "about" */ "../components/404.vue"),

  },
  {
    path: "*",
    redirect: "/404",
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
